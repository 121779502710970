import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffcc45",
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: ["Monsterrat", "Roboto", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "2rem",
      marginBottom: 16,
    },
    body1: {
      marginBottom: 8,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          // textDecoration: "underline",
          color: "inherit",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        expandIconWrapper: {
          color: "inherit",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
})
