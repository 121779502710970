import React from "react"
import { ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { GatsbySSR } from "gatsby"
import { theme } from "./src/theme"

export const wrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  )
}
